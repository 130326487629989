import React from "react";
import { Tag, Icon, Modal } from "antd";
import moment from 'moment';
import NumberFormat from '../../../functions/numberFormat';
import Button from '../../../components/componentsUI/Button';
import Mispichoshelper from "../../../utils/Mispichoshelper";
import { Col, Row } from "shards-react";

export default function InfoRight({ compra }) {
	let totalRefunded = 0;
	let productos = compra.productos;
	return (
		<div className="InfoRight">
			<div>
				<div className="ListaProductos">
					{productos.map((item, o) =>
						<div key={o} className="compraResumenListaProductos">
							<img src={item.prv_foto_link} alt='img' />
							<strong>{item.prv_producto_nombre}
								<br></br>
								{item.prv_codigo_barra && item.prv_codigo_barra != null ? '(' + item.prv_codigo_barra + ')' : 'No posee EAN'}
								<br></br>
								{'c/u $' + item.cor_precio + ' x ' + item.cor_cantidad + "uds."}
							</strong>
							<div>
								${item.cor_precio * item.cor_cantidad}
							</div>
						</div>
					)}
				</div>
				{compra?.productos_combinados && compra.productos_combinados.map((item, o) =>
					<div className="ListaProductos">
						<div key={o} className="compraResumenListaProductos">
							<img src={item.prv_foto_link} alt='img' />
							<strong>{item.full_name}
								<br></br>
								({item?.rows && item.rows.map(row => row.prv_codigo_barra ? row.prv_codigo_barra : 'No posee EAN').join(' - ')})
								<br></br>
								{'c/u $' + item.cor_precio + ' x ' + item.cor_cantidad + "uds."}
							</strong>
							<div>
								${item.cor_precio * item.cor_cantidad}
							</div>
						</div>
					</div>
				)}
				<RefundsBlock order={compra} />
			</div>
			<div>
				<ul className='CompraSumaTotal'>
          <li>Costo de productos <span className='si'>$ {compra.compra_total_productos}</span></li>
					<li>Descuentos Cupón <span className='si'>{compra.compra_descuento ? `-$ ${compra.compra_descuento}` : '$ 0'}</span></li>
					<li>Descuento PichoPesos <span className='si'>{compra.compra_credito ? `-$ ${compra.compra_credito}` : '$ 0'}</span></li>
          <li>Costo de envío <span className='si'>{compra.compra_costo_envio ? `$ ${compra.compra_costo_envio}` : '$ 0'}</span></li>
          <li>Costo financiero <span className='si'>{compra.compra_costo_financiero ? `$ ${compra.compra_costo_financiero}` : '$ 0'}</span></li>
					<li>Valor Total <span>$ {compra.compra_total_full}</span></li>
					{compra.compra_link_edicion && !compra.compra_estado_pago_edicion ?
						<>
							<li className="pr-2" style={{ display: 'flex', alignItems: 'center' }}>
								Saldo por edición de compra
								<span style={{ display: 'flex', alignItems: 'center' }}>
									${compra.compra_saldo_edicion}
									<Icon style={{ marginRight: '10px' }} type="link" className='compraAccionBtn' onClick={() => navigator.clipboard.writeText(compra.compra_link_edicion)} />
									{compra.compra_estado_id == 4 && <Button type={'outline-primary'} labelText='Mis Pichos se hace cargo' onPress={() => confirmToPassToPayed(compra.compra_id)} />}
								</span>
							</li>

						</>
						: (compra.compra_estado_pago_edicion ? <li><span>{`Estado pago del saldo -> ${compra.compra_estado_pago_edicion}`}</span></li> : '')}
					{compra.total_descuento_petshop && compra.total_descuento_petshop !== 0 ?
						<>
							<Row className='justify-content-end'>
								<Col xs={12} md={5} className='d-flex justify-content-end mt-2'>
									<span style={{ background: 'green', color: 'white', padding: 5, borderRadius: 12 }}>La fábrica te paga + ${compra.total_descuento_petshop}</span>
								</Col>
							</Row>
						</>
						: null
					}

				</ul>

				<ul style={{ listStyle: 'none', paddingLeft: 0 }}>
					{compra.payment_data_refunds && Symbol.iterator in Object(compra.payment_data_refunds) && compra.payment_data_refunds.map((eachRefund, i) => {
						totalRefunded += eachRefund.amount;
						return <li><Tag color='Tomato'>Devolución {`=>`} {`Fecha:${moment(eachRefund.date_created).format("DD/MM/YYYY HH:mm:ss")} Monto:$${eachRefund.amount} ID:${eachRefund.id}`}</Tag></li>
					}
					)}

					{compra.payment_data_edit_refunds.map((eachRefund, i) => {
						totalRefunded += eachRefund.amount;
						return <li><Tag color='Tomato'>Devolución Edición {`=>`} {`Fecha:${moment(eachRefund.date_created).format("DD/MM/YYYY HH:mm:ss")} Monto:$${eachRefund.amount} ID:${eachRefund.id}`}</Tag></li>
					})}

					{totalRefunded > 0 ?
						<li><Tag color='Green'>Total devuelto al cliente {totalRefunded}</Tag></li>
						: null}

				</ul>
			</div>

		</div >
	)
}

function RefundsBlock({ order }) {
	if (order.resolved_refunds.length == 0) {
		return null;
	}
	return (
		<div className="ListaProductos" >
			<hr style={{ marginTop: 40, backgroundColor: 'red', height: '1', width: '100%' }}></hr>
			<h4>Devoluciones parciales de productos</h4>
			<h6>Saldo en prisma actual ${order.payment_data_refunds.payment_response != undefined ? (order.payment_data_refunds.payment_response.amount).toString().slice(0, -2) : 'sin definir'}</h6>
			<br></br>
			{order.resolved_refunds.map((block, o) => {
				return block.data != undefined && block.data.length > 0 && block.data.map((item, o) =>
					<div>
						{
							item.nueva_cantidad ?
								<div key={o} className="compraResumenListaProductos">
									<img src={item.prv_foto_link} alt='img' />
									<strong>{item.nueva_cantidad} x {item.prv_producto_nombre}
										<br></br>
									</strong>
									<div>
										${item.new_total_amount}
									</div>
								</div>
								:
								<div key={o} className="compraResumenListaProductos">
									<img src={item.prv_foto_link} alt='img' />
									<strong>{item.prv_producto_nombre}
										<br></br>

										{'c/u $' + item.cor_precio + ' x ' + item.cor_cantidad + "uds."}
									</strong>
									<div>
										${item.cor_precio * item.cor_cantidad}
									</div>
								</div>
						}



					</div>

				)
			}
			)}
		</div>
	)
}

// --- Funciones

const confirmToPassToPayed = (order_id) => {
	Modal.confirm({
		title: '¿Está seguro de marca el pedido #' + order_id + ' como pagado?',
		content: 'el saldo por edición de compra quedara como un monto que no fue pagado y por lo tanto lo asumirá la empresa',
		onOk: () => passToPayed(order_id),
		okText: 'Si, Asumír saldo',
		cancelText: 'Cancelar'
	});
}


const passToPayed = (order_id) => {

	Mispichoshelper.postPassOrderToPayed({ order_id: order_id }).then((response) => {
		if (response.status) {
			Modal.success({
				title: response.payload.title,
				content: response.payload.message,
				onOk: () => { window.location.reload(); },
			});
		} else {
			Modal.error({
				title: response.payload.title,
				content: response.payload.message,
			});
		}
	})
}
